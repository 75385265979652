<template>
  <div
    :class="['person-sign',clientWidth<=mobileWidth?'mobile-bg':'']">
    <video autoplay loop muted data-keepplaying  class="bgvid" v-if="clientWidth>mobileWidth">
      <source src="https://iaip.lngsyz.com/profile/gs/sea.mp4" type="video/mp4">
    </video>
    <div class="person-sign-content">
      <p class="sign-title">个人签名</p>
      <p class="sign-info">支持使用手机端、手写板等设备进行个人签名，对签名文档进行加密保护。</p>
      <div class="sign-btn" @click="toView">马上体验</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PersonSign',
  props: {
    clientWidth: {
      type: Number,
      default: 1920,
    },
  },
  computed: {
    mobileWidth() {
      return this.$store.state.common.mobileWidth;
    },
  },
  methods: {
    toView() {
      window.open('http://218.60.150.252:9980/docs/sign', '_black');
    },
  },
};
</script>

<style lang="less" scoped>
    .bgvid {
      position: absolute;
      right:0;
      bottom:0;
      min-width:100%;
      min-height:100%;
      width: auto;
      height:auto;
      z-index:-100;
      background-size: 100% 100%;
    }
  .person-sign {
    height: 100%;
    display: flex;
    font-family: PingFangSC-Medium, PingFang SC;
    width: 100%;
    position: relative;
    background:rgba(221, 221, 221, 0.4) url("https://iaip.lngsyz.com/profile/gs/video.mp4") no-repeat center 0px;
    .person-sign-content{
      display: flex;
      height: 100%;
      align-self: center;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    p {
      margin: 0;
    }
    .sign-title {
      font-size: 32px;
      font-weight: 500;
      color: #333;
    }
    .sign-info {
      font-size: 18px;
      font-weight: 500;
      margin-top: 20px;
      color: #333;
    }
    .sign-btn {
      width: 200px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      background: #FFFFFF;
      border-radius: 30px;
      font-size: 21px;
      font-weight: 400;
      color: #597EF7;
      letter-spacing: 1px;
      margin-top: 83px;
      cursor: pointer;
    }
  }
  .mobile-bg{
    background: #fff;
    background: url("../../../assets/imgs/bg_sign@2x.png") center center no-repeat;
    background-size: cover;
    height: calc(~"100% - 315px");
      .sign-title,.sign-info {
        color: rgba(255, 255, 255, 0.85);
      }
  }
</style>
