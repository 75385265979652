<template>
  <div class="seal-system">
    <div class="seal-system-content common-mobile-content">
      <div class="sys-pic-content" v-if="clientWidth>mobileWidth">
        <img src="../../../assets/imgs/pc_pic_kehuduan@2x.png" class="pic-sys pc-pic-sys">
        <img src="../../../assets/imgs/kehuduan.gif" class="pc-sys-gif">
       </div>
      <div class="sys-info">
        <div class="common-info-content">
          <img src="../../../assets/imgs/icon_kehuduan@2x.png">
          <div class="apply-title">广烁电子印章客户端</div>
        </div>
        <div class="apply-js common-apply-js">本地化多种签署方式，满足各种签章需求。</div>
        <div class="experience-btn" @click="toDownload" v-if="clientWidth>mobileWidth">前往下载</div>
      </div>
      <img src="../../../assets/imgs/pic_kehuduan@2x.png"
       class="pic-sys common-pic-icon common-pic-long"
       v-if="clientWidth<=mobileWidth">
      <div class="experience-btn" @click="toDownload" v-if="clientWidth<=mobileWidth">前往下载</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SealSystem',
  data() {
    return {
    };
  },
  props: {
    clientWidth: {
      type: Number,
      default: 1920,
    },
  },
  computed: {
    mobileWidth() {
      return this.$store.state.common.mobileWidth;
    },
  },
  methods: {
    toDownload() {
      window.open('/app/广烁电子签章系统(客户端)V2.0.1.19_政企通用互联网版(20240529).msi', '_black');
    },
  },
};
</script>

<style lang="less" scoped>
  .seal-system {
    height: 100%;
    display: flex;
    justify-content: center;
    background: #F3F3F3;
    .seal-system-content{
      display: flex;
      height: 488px;
      justify-content: center;
      align-self: center;
    }
    .pic-sys {
      width: 551px;
      height: 392px;
    }
    .pc-pic-sys{
      width: 815px;
      height: 458px;
    }
    .sys-pic-content{
      position: relative;
      height: 458px;
    }
    .pc-sys-gif{
      position: absolute;
      left: 220px;
      bottom: 57px;
      width: 430px;
      border-radius: 4px;
    }
    .sys-info {
      width: 360px;
      margin-left: -22px;
      font-family: PingFangSC-Regular, PingFang SC;
      img {
        width: 72px;
        height: 72px;
        margin-top: 62px;
      }
      .apply-title {
        padding-left: 8px;
        margin-top: 8px;
        font-size: 28px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
      }
      .apply-js {
        padding-left: 8px;
        margin-top: 16px;
        font-size: 18px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.45);
        opacity: 0;
        transition: all 1s;
        transform: scale(0,0);
        transition-delay: .8s;
      }
    }
    .experience-btn {
      width: 200px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      background: #597EF7;
      border-radius: 30px;
      font-size: 21px;
      font-weight: 400;
      color: #FFFFFF;
      letter-spacing: 1px;
      margin-top: 16px;
      cursor: pointer;
      margin-top: 120px;
      opacity: 0;
      transition: opacity 1s ease-out 1.2s,
      margin-top 1s ease-out 1.2s, color 0.3s, background-color 0.3s;
    }
  }
</style>
