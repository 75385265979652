<template>
  <div class="seal-sign">
    <div class="seal-sign-content common-mobile-content">
      <img src="../../../assets/imgs/pic_qianzhang@2x.png"
       class="pic-sign common-pic-icon pc-pic-sign" v-if="clientWidth>mobileWidth"/>
      <div class="sign-info">
        <div class="common-info-content">
          <img src="../../../assets/imgs/icon_qianzhang@2x.png">
          <div class="apply-title">电子印章签署</div>
        </div>
        <div class="apply-js common-apply-js">支持多场景、多类型电子文件签署，以权威的密码技术和完善的管理手段保证签章文档合法有效性。</div>
        <div class="experience-btn" @click="toView" v-if="clientWidth>mobileWidth">马上体验</div>
      </div>
      <template v-if="clientWidth<=mobileWidth">
        <img src="../../../assets/imgs/pic_qianzhang@2x.png" class="pic-sign w100-pic"/>
        <div class="experience-btn" @click="toView">马上体验</div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SealSign',
  data() {
    return {
    };
  },
  props: {
    clientWidth: {
      type: Number,
      default: 1920,
    },
  },
  computed: {
    mobileWidth() {
      return this.$store.state.common.mobileWidth;
    },
  },
  methods: {
    toView() {
      window.open('http://218.60.150.252:9980/docs/sign', '_black');
    },
  },
};
</script>

<style lang="less" scoped>
  .seal-sign {
    height: 100%;
    display: flex;
    justify-content: center;
    background: #F3F3F3;
    .seal-sign-content{
      display: flex;
      height: 444px;
      justify-content: center;
      align-self: center;
    }
    .sign-info {
      width: 360px;
      font-family: PingFangSC-Regular, PingFang SC;
      margin: 45px 0 0 -18px;
      margin: auto;
      img {
        width: 72px;
        height: 72px;
      }
      .apply-title {
        padding-left: 8px;
        margin-top: 8px;
        font-size: 28px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
      }
      .apply-js {
        padding-left: 8px;
        margin-top: 16px;
        font-size: 18px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.45);
        opacity: 0;
        transition: all 1s;
        transform: scale(0,0);
        transition-delay: .8s;
        line-height: 30px;
      }
    }
    .experience-btn {
      width: 200px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      background: #597EF7;
      border-radius: 30px;
      font-size: 21px;
      font-weight: 400;
      color: #FFFFFF;
      letter-spacing: 1px;
      margin-top: 24px;
      cursor: pointer;
      margin-top: 120px;
      opacity: 0;
      transition: opacity 1s ease-out 1.2s,
      margin-top 1s ease-out 1.2s, color 0.3s, background-color 0.3s;
    }
    .pic-sign {
      width: 740px;
      height: 401px;
    }
    .pc-pic-sign{
      width: 819px;
      height: 444px;
    }
  }
</style>
